import "../../../resources/less/components/Popups/TroubadourPopup.less";

class TroubadourPopup {
  constructor() {
    this.popupContainer = $(".troubadour-popup-container");
    this.triggerButton = $(".popup-trigger-button");
    this.closeButton = $(".close-button");

    this.triggerButton.on("click", (element) => {
      const { target } = element.currentTarget.dataset;
      $(target).removeClass("hide");
    });

    this.closeButton.on("click", () => {
      this.popupContainer.addClass("hide");
    });

    this.popupContainer.on("click", (e) => {
      if ($(e.target).closest($(".troubadour-popup-section")).length === 0) {
        this.popupContainer.addClass("hide");
      }
    });
  }
}

$(window).on("load", () => {
  new TroubadourPopup();
});
